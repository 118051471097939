import React from 'react'
import { Icon, Label, Menu, Table, Card, Button } from 'semantic-ui-react'


import { faStar } from '@fortawesome/free-solid-svg-icons';
import moment from "moment-timezone";
import { Link } from 'react-router-dom';



const MyRow =(props) => (
  <tr>
      <th scope="row">{props.lable}</th>
      <td>{props.value}</td>
  </tr>
);

const LinkRow =(props) => (
  <tr>
      <th scope="row">{props.lable}</th>
    <td>
      <Link to={`/${props.path}/${props.value}`}>Open</Link>
    </td>
  </tr>
);

const RowElement =(props) => (
  <span>{props.value}<br></br></span>
);




const ShowJobDetails = (props) => (
  <>
  <table class="table">
  <tbody>
    
  <MyRow lable="Job ID" value={props.data.job_id} />
  <MyRow lable="Posted On" value={moment(props.data.created_at).format('Do MMM YY')} />
  <MyRow lable="Started At" value={moment(props.data.start_date).format('Do MMM YY')+' '+props.data.start_time} />
      <MyRow lable="Ended At" value={moment(props.data.end_date).format('Do MMM YY') + ' ' + props.data.end_time} />
      
  <MyRow lable="Job location" value={props.data.job_location} />
  <MyRow lable="Job details" value={props.data.job_details} />
        <MyRow lable="Job status" value={props.data.job_status} />
        {(props.data.sitter_id) ? <LinkRow lable="Sitter Details" path="sitter" value={props.data.sitter_id} />: ''}
  
        <LinkRow lable="Parent Details" path="user" value={props.data.parent_id} />
        {(props.data.rating) ? <MyRow lable="Job Rating" value={props.data.rating} />: ''}
        {(props.data.review) ? <MyRow lable="Job review" value={props.data.review} />: ''}
  <tr>
    <th scope="row">Open Location in Map</th>
    <td>
    <a target="_blank" href={"http://maps.google.com/?q="+props.data.lat+','+props.data.lng}>Open</a>
    </td>
  </tr>
        
       
    
{/* 
    {(props.variant == 'teacher') ? <MyRow 
    lable="Subjects"
    value={props.subjects} /> : ''}
     */}
  
    
    {/* {(props.variant == 'teacher') ? <MyRow 
    lable="description"
    value={props.description} /> : ''}
    
    {(props.variant == 'teacher') ?<tr>
      <th scope="row">Total Rating</th>
      <td> <Icon  name='star' /> { props.avg_rating }</td>
  </tr>: ''} */}
 
 

 <br></br>
 


  </tbody>
    </table>
    {/* <iframe 
  width="700" 
  height="400" 
  frameborder="0" 
  scrolling="no" 
  marginheight="0" 
  marginwidth="0" 
  src="https://maps.google.com/maps?q=30.6804481,76.726084&hl=es&z=14&amp;output=embed"
    >
      
 </iframe> */}
 
  </>
)

export default ShowJobDetails


